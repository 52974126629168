import { withPrefix } from 'gatsby-link';
import React, { PureComponent } from 'react';

class Pricing extends PureComponent {
  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      const detected = window.localStorage.getItem('language') || 'ru';

      const newUrl = withPrefix(`/${detected}/pricing/public-cloud/`);
      window.location.replace(newUrl);
    }
  }

  render() {
    return <div />;
  }
}

export default Pricing;
